// import { HeadlineH6BoldCSS, TextBodySmallCSS } from 'src/helper/nectUI';
import { HeadlineH6BoldCSS, TextBodySmallCSS } from 'src/helper/nectUI';
import styled from 'styled-components';
// import { HeadlineH6BoldCSS } from '@nect/ui/component/headline/headline.styled';
// import { TextBodySmallCSS } from '@nect/ui/component/text/text.styled';

const Box = styled.div`
  padding: 32px;
  border-radius: 8px;
  background-color: #edf7fb;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 30px;
  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

const BoxTitle = styled.h2`
  font-size: 1rem;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  line-height: 1.5rem;
  margin: 0;
  margin-bottom: 8px;
  margin-top: 8px;
  ${HeadlineH6BoldCSS}
  color: ${(props: any) => props.theme.black};
`;

const BoxMessage = styled.p`
  font-family: Heebo, sans-serif;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: 0.04em;
  margin-top: 4px;
  padding-top: 0px;
  ${TextBodySmallCSS}
  color: ${(props: any) => props.theme.black};
  @media (max-width: 768px) {
    text-align: left;
  }

  > a {
    display: inline-block;
    color: #2f80ed;
    text-decoration: underline;
  }
`;

export const FaqStyled = {
  Box,
  BoxTitle,
  BoxMessage
};
