/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import './App.css';
import { HomeScreen } from './screens/HomeScreen';
import { routes, initialState } from './constants/constants';
import { StateProvider, mainReducer, useStateValue } from './hooks/useState';

import { QRScreen } from './screens/QRScreenNew';
import { IdentScreen } from './screens/IdentScreenNew';
import { SMSScreen } from './screens/SMSScreen';
import { OfflineScreen } from './screens/Offline';

import { IncompatibilityDeviceScreen } from './screens/IncompatibilityDevice';
import { useRouter } from './react-router-hooks';

// import { useTransition } from '@react-spring/core';
import { animated } from '@react-spring/web';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { useWideScreen } from './hooks/useWide';
import { IFrameScreen } from './screens/IFrameScreen';
import { createBrowserHistory } from 'history';
import { NoCaseScreen } from './screens/NoCaseScreenNew';
import { getItem, setItem } from './helper/storage';
import { postEvent } from './api/postEvent';
import { ErrorScreen } from './screens/ErrorScreenNew';

// import {
//   NectDefaultTheme,
//   NectDefaultGlobalStyle
// } from '@nect/ui/theme/nect-default';
import { FeedbackBox } from './components/FeedbackBox/FeedbackBox';
import { StartIdentScreen } from './screens/StartIdent';
import { NectDefaultTheme } from './helper/nectUI';

const customHistory = createBrowserHistory();
const push = customHistory.push;

/**
 * Attach case_uuid to url search if available
 */
customHistory.push = (args: any) => {
  const redirectURL = getItem('redirect_url');
  const uuid = getItem('uuid');

  if (typeof args === 'string') {
    if (typeof uuid === 'string') {
      args = {
        pathname: args,
        search:
          typeof redirectURL === 'string'
            ? `?case_uuid=${uuid}&redirect_url=${encodeURIComponent(
                redirectURL
              )}`
            : `?case_uuid=${uuid}`
      };
    }
  } else if (typeof args === 'object') {
    if (typeof uuid === 'string' && !args.search) {
      args.search =
        typeof redirectURL === 'string'
          ? `?case_uuid=${uuid}&redirect_url=${encodeURIComponent(redirectURL)}`
          : `?case_uuid=${uuid}`;
    }
  }

  push(args);
};

const Wrapper = ({ children }: any): any => {
  const { location } = useRouter();

  // const transitions = useTransition();

  const [{ feedbackBoxVisible }, dispatch]: any = useStateValue();

  const ref: any = useRef(false);

  const onLeave = useCallback((e: any) => {
    if (
      location.pathname === '/' ||
      location.pathname === '/qr' ||
      location.pathname === '/sms'
    ) {
      if (getItem('do-not-show-popup-again')) return;
      dispatch({ type: 'CHANGE_FEEDBACK_VISIBILITY', payload: true });
      setItem('do-not-show-popup-again', 'true');
    }
  }, []);

  const onClose = () => {
    dispatch({ type: 'CHANGE_FEEDBACK_VISIBILITY', payload: false });
  };

  useEffect(() => {
    if (ref.current && typeof ref.current.addEventListener === 'function') {
      setTimeout(() => {
        ref.current.addEventListener('mouseleave', onLeave);
      }, 90000);
    }

    return () => ref.current.removeEventListener('mouseleave', onLeave);
  }, [onLeave]);

  return (
    <animated.div
      ref={ref}
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        opacity: 1
      }}
    >
      {feedbackBoxVisible && (
        <FeedbackBox
          onClose={onClose}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onSubmitted={() => {}}
        />
      )}
      <Switch location={location}>{children}</Switch>
    </animated.div>
  );
};

class ErrorBoundary extends React.Component {
  state = {
    error: false
  };

  componentDidCatch(error: Error) {
    const uuid = getItem('uuid') || '';

    postEvent({ uuid, event: 'WEB.JUMP.ERROR', details: { error } });

    this.setState({ error: true });
  }

  render() {
    if (this.state.error) {
      return <ErrorScreen />;
    }
    return this.props.children;
  }
}

export const App = () => {
  const isWideScreen = useWideScreen();

  useEffect(() => {
    const uuid = getItem('uuid') || '';
    postEvent({
      uuid,
      event: isWideScreen ? 'WEB.JUMP.DESKTOP' : 'WEB.JUMP.MOBILE',
      details: { uuid }
    });
  }, [isWideScreen]);

  return (
    <ThemeProvider theme={{ ...theme, ...NectDefaultTheme }}>
      {/* <NectDefaultGlobalStyle /> */}
      <Router history={customHistory}>
        <StateProvider initialState={initialState} reducer={mainReducer}>
          <ErrorBoundary>
            <Wrapper>
              <Route
                exact
                path={routes.HOME}
                component={isWideScreen ? QRScreen : HomeScreen}
              />
              <Route exact path={routes.QR} component={QRScreen} />
              <Route exact path={routes.IDENT} component={IdentScreen} />
              <Route exact path={routes.SMS} component={SMSScreen} />
              <Route exact path={routes.OFFLINE} component={OfflineScreen} />
              <Route
                exact
                path={routes.INCOMPATIBILITY}
                component={IncompatibilityDeviceScreen}
              />
              <Route exact path={routes.IFRAME} component={IFrameScreen} />
              <Route exact path={routes.NOCASE} component={NoCaseScreen} />
              <Route
                exact
                path={routes.STARTIDENT}
                component={StartIdentScreen}
              />
              <Route
                component={
                  isWideScreen ? QRScreen : HomeScreen
                } /* '404' route */
              />
            </Wrapper>
          </ErrorBoundary>
        </StateProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
