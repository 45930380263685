import styled from 'styled-components';

const Wrapper = styled.div`
  padding-top: 20px;
`;

const Title = styled.div`
  color: #0a0d1b;
  font-size: 14px;
  font-family: Heebo, sans-serif;
  font-weight: 500;
  margin-bottom: 4px;
  letter-spacing: -0.25px;
`;

const Subtitle = styled.div`
  color: #4b4f55;
  font-size: 12px;
  line-height: 16px;
  font-family: Heebo, sans-serif;
  font-weight: 400;
  margin-bottom: 8px;
  letter-spacing: -0.25px;
`;

const FieldBoxWrapper = styled.div`
  display: flex;
  font-size: 14px;
  margin-bottom: 8px;
`;

const FieldBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`;

const Input = styled.input`
  flex: 1;
  padding: 11px 8px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #dddee0;
  /* height: 40px; */
  outline: none;
  width: 100%;
  direction: ltr;

  :hover {
    border-color: #797c81;
  }

  :focus {
    border-color: #0a0d1b;
  }

  &.error {
    border-color: #f01c4f;
  }

  &.success {
    border-color: #11c6b0;
  }
`;

const Error = styled.div`
  color: #f01c4f;
  font-family: Heebo, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.25px;
  margin-bottom: 8px;
`;

const Consent = styled.div`
  color: #797c81;
  font-family: Heebo, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: -0.25px;
`;

export const SMSStyled = {
  Wrapper,
  Title,
  Subtitle,
  FieldBox,
  Input,
  Error,
  Consent,
  FieldBoxWrapper
};

export const StretchColumn = styled.div`
  flex-grow: 1;
`;

export const Button = styled.button`
  padding: 12px 16px;
  font-family: Heebo, sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  background-color: #0e1528;
  border-radius: 4px;
  height: 40px;
  color: ${(props: any) => props.theme.white};
  border: none;
  cursor: pointer;

  :hover {
    background-color: #1b2a50;
  }

  :disabled {
    background-color: #dddee0;
    cursor: auto;
  }
`;

export const SuccessBtn = styled.div`
  padding: 12px;
  font-family: Heebo, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.25px;
  text-align: center;
  background-color: #f0fdfb;
  border-radius: 4px;
  min-height: 40px;
  color: #11c6b0;
`;
