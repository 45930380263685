/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled from 'styled-components';
import { IFeedbackItem } from './ItemBox';

export const RightRow = styled.div`
  display: flex;
  width: 100%;
`;

export const Text = styled.h3`
  color: ${(props: any) => props.theme.grey};
  font-size: 13px;
  line-height: 19px;
  font-weight: 500;
  margin-top: 16px;
  margin-bottom: 0;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props: any) => props.theme.bgGrey};
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  > h1 {
    display: grid;
    place-items: center;
    padding: 91px 51px 51px;
  }
`;

export const Row = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
`;

export const SelectedText = styled.p`
  font-family: Heebo, sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  margin-left: 8px;
  color: ${(props: any) => props.theme.primary};
`;

export const FeedbackItemBox = styled.div<IFeedbackItem>`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;

  border: 1px solid
    ${(props: any) =>
      props.selected ? props.theme.snackInfoBase : props.theme.grayLight};
  background: ${(props: any) =>
    props.selected ? props.theme.snackInfoLightBase : props.theme.white};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 15px 12px;
  cursor: pointer;
  width: 100%;
  color: ${(props: any) => props.theme.grey700};

  span:nth-child(1) {
    background: ${(props: any) =>
      props.selected ? props.theme.snackInfoBase : props.theme.white};
    color: ${(props: any) =>
      props.selected ? props.theme.white : props.theme.grey400};
    padding: 0 4px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
      0px 0px 0px 1px rgba(70, 79, 96, 0.16);
    border-radius: 2px;
  }

  span {
    font-size: 13px;
    line-height: 16px;
  }

  :hover {
    border: 1px solid ${(props: any) => props.theme.snackInfoBase};
  }
`;

export const ThankYouMessageStyled = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -1px;
  color: ${(props: any) => props.theme.grey700};
`;

export const Title = styled.h1`
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  letter-spacing: -0.5px;
  color: ${(props: any) => props.theme.grey700};
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  padding: 20px 24px;
  border-bottom: 1px solid #dddee0;
  align-items: center;
`;

export const FeedbackOptionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  padding: 0 24px 80px;
  gap: 8px;
`;

export const FeedbackWrapper = styled.div`
  position: fixed;
  display: block;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 42;
`;

export const FeedbackBoxStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fafbfc;
  box-shadow: 0px 0px 0px 1px rgba(152, 161, 179, 0.1),
    0px 15px 35px -5px rgba(17, 24, 38, 0.2), 0px 5px 15px rgba(0, 0, 0, 0.08);
  min-width: 400px;
  border-radius: 8px;
  z-index: 43;
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const CloseIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #dddee0;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;

  background: url(${require('../../assets/close.png')});
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: center;
`;
