import React, { useState, useCallback, useEffect } from 'react';

import styles from './Header.module.css';
import { useWideScreen } from '../../hooks/useWide';
import { useLoadedFromApp } from '../../hooks/useLoadedFromApp';
import { Menu } from '../Menu/Menu';
import { Button } from '../Button/Button';
import { routes } from '../../constants/constants';
import { useStateValue } from '../../hooks/useState';
import { useTranslation } from 'react-i18next';
import { StoreBadge } from '../StoreBadge/StoreBadge';
import { useLocation } from '../../react-router-hooks';
import { Link } from 'react-router-dom';
import { getItem } from '../../helper/storage';
import { TextButtonLarge } from 'src/helper/nectUI';
// import { TextButtonLarge } from '@nect/ui/component/text';

interface Props {
  isFormHelp?: boolean;
  router?: any;
  showStartButton?: boolean;
  isIFrame?: boolean;
  isFormFeedback?: boolean;
  noBadge?: boolean;
}

export const Header = (props: Props) => {
  const isWideScreen = useWideScreen();
  const { navigate, location } = useLocation();

  const isLoadedFromApp = useLoadedFromApp();

  const testPublicSPID = getItem('test_public_spid');
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [{ uuid, caseStatus, coBranding }] = useStateValue();
  const { t } = useTranslation();

  const [src, setSrc] = useState(
    'https://cms.nect.com/assets/default-jump.png'
  );

  useEffect(() => {
    if (testPublicSPID) {
      setSrc('https://cms.nect.com/assets/' + testPublicSPID + '-jump.png');
    } else if (
      caseStatus &&
      caseStatus.publicSPID &&
      coBranding &&
      coBranding.logo
    ) {
      const url = coBranding.logo;

      fetch(url)
        .then(() => {
          setSrc(url);
        })
        .catch(error => console.warn(error));
    } else {
      setSrc('');
    }
  }, [caseStatus, testPublicSPID]);

  const toggleMenu = useCallback(() => {
    const el = document.getElementById('main');

    //FIXME: hide the main content if the menu is open...find better solution
    if (el) {
      el.style.display = isMenuVisible ? 'inherit' : 'none';
    }

    setIsMenuVisible(!isMenuVisible);
  }, [setIsMenuVisible, isMenuVisible]);

  if (isMenuVisible) {
    return <Menu close={toggleMenu} />;
  }

  return (
    <header
      className={isWideScreen ? styles.headerDesktop : styles.headerMobile}
    >
      <div>
        <img
          className={isWideScreen ? styles.iconDesktop : styles.iconMobile}
          style={{ height: 30, width: 34 }}
          src={require('../../assets/nect-logo-black.svg')}
        />
      </div>
      {!['/', '/nocase'].includes(location.pathname) && (
        <TextButtonLarge style={{ flex: '1 0 auto', paddingLeft: 16 }}>
          {t('Help Centre')}
        </TextButtonLarge>
      )}

      {!props.isIFrame && isWideScreen && src && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={require('../../assets/close-cross.png')}
            style={{ height: 24, marginLeft: 8, marginRight: 8 }}
          />
          <img src={src} style={{ height: 30 }} />
        </div>
      )}

      {/* TODO: lang select */}

      <div
        style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}
      >
        {isWideScreen && props.isFormHelp && (
          <div className={styles.button}>
            <Link className={styles.buttonForm} to={props.router}>
              {t('Send Feedback')}
            </Link>
          </div>
        )}
        {!props.isIFrame && isWideScreen && props.showStartButton && uuid && (
          <div className={styles.button}>
            <Button to={routes.QR} text={t('Get Started')} />
          </div>
        )}

        {!isWideScreen && !props?.noBadge && !isLoadedFromApp && (
          <div>
            <div className={styles.button}>
              <StoreBadge
                style={{
                  paddingLeft: 19,
                  paddingRight: 19,
                  paddingTop: 6,
                  paddingBottom: 7
                }}
                type="depending"
                target="_self"
                onBackground="header"
                ownButton
                isFormHelp={props.isFormHelp}
                isFormFeedback={props.isFormFeedback}
              />
              <img
                className={styles.menu}
                onClick={toggleMenu}
                src={require('../../assets/menu.svg')}
              />
            </div>
          </div>
        )}
      </div>
    </header>
  );
};
