/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';

export type ColorShade = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

export const hexAToRGBA = (h: string) => {
  let r = '0';
  let g = '0';
  let b = '0';
  let a: string | number = 1;

  if (h.length === 5) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
    a = `0x${h[4]}${h[4]}`;
  } else if (h.length === 9) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
    a = `0x${h[7]}${h[8]}`;
  }
  a = +((a as number) / 255).toFixed(3);

  return `rgba(${+r},${+g},${+b},${a})`;
};

type RGB = {
  red: number;
  green: number;
  blue: number;
};

export const hexToRGBAString = (colorValue: string, opacity = 1): string => {
  return `rgba(${parseInt(colorValue.substr(0, 2), 16)},${parseInt(
    colorValue.substr(2, 2),
    16
  )},${parseInt(colorValue.substr(4, 2), 16)},${opacity})`;
};

// From https://github.com/edelstone/tints-and-shades/blob/6cd92a04b654b8bffa5d7d7094cba1f300ee5f1e/scripts/tints-and-shades.js
const hexToRGB = (colorValue: string): RGB => {
  return {
    red: parseInt(colorValue.substr(0, 2), 16),
    green: parseInt(colorValue.substr(2, 2), 16),
    blue: parseInt(colorValue.substr(4, 2), 16)
  };
};

// pad a hexadecimal string with zeros if it needs it
function pad(number: string, length: number) {
  // eslint-disable-next-line prefer-template
  let str = '' + number;
  while (str.length < length) {
    str = `0${str}`;
  }
  return str;
}

// convert an integer to a 2-char hex string
// for sanity, round it and ensure it is between 0 and 255
// 43 => '2b'
function intToHex(rgbint: number) {
  return pad(Math.min(Math.max(Math.round(rgbint), 0), 255).toString(16), 2);
}

// convert one of our rgb color objects to a full hex color string
// { red: 80, green: 18, blue: 20 } => '501214'
function rgbToHex(rgb: RGB) {
  return intToHex(rgb.red) + intToHex(rgb.green) + intToHex(rgb.blue);
}

// shade one of our rgb color objects to a distance of i*10%
// ({ red: 80, green: 18, blue: 20 }, 1) => { red: 72, green: 16, blue: 18 }
const rgbShade = (rgb: RGB, i: number): RGB => {
  return {
    red: rgb.red * (1 - 0.1 * i),
    green: rgb.green * (1 - 0.1 * i),
    blue: rgb.blue * (1 - 0.1 * i)
  };
};

// tint one of our rgb color objects to a distance of i*10%
// ({ red: 80, green: 18, blue: 20 }, 1) => { red: 98, green: 42, blue: 44 }
function rgbTint(rgb: RGB, i: number) {
  return {
    red: rgb.red + (255 - rgb.red) * i * 0.1,
    green: rgb.green + (255 - rgb.green) * i * 0.1,
    blue: rgb.blue + (255 - rgb.blue) * i * 0.1
  };
}

export enum Color {
  /**
   *
   * Primary Colors
   *
   */

  // Nect Green

  /** @color Nect Green / 5% */
  nectGreenOpacity5 = 'Nect Green 5%',

  /** @color Nect Green / 100 Tint */
  nectGreen100 = 'Nect Green 100 Tint',
  /** @color Nect Green / 200 Tint */
  nectGreen200 = 'Nect Green 200 Tint',
  /** @color Nect Green / 300 Tint */
  nectGreen300 = 'Nect Green 300 Tint',
  /** @color Nect Green / 400 Tint */
  nectGreen400 = 'Nect Green 400 Tint',

  /** @color Nect Green / 500 Base */
  nectGreen500 = 'Nect Green 500 Base',

  /** @color Nect Green / 600 Shade */
  nectGreen600 = 'Nect Green 600 Shade',
  /** @color Nect Green / 700 Shade */
  nectGreen700 = 'Nect Green 700 Shade',
  /** @color Nect Green / 800 Shade */
  nectGreen800 = 'Nect Green 800 Shade',
  /** @color Nect Green / 900 Shade */
  nectGreen900 = 'Nect Green 900 Shade',

  // Primary Blue
  /** @color Primary Blue / 5% */
  primaryBlueOpacity5 = 'Primary Blue 5%',

  /** @color Primary Blue / 100 Tint */
  primaryBlue100 = 'Primary Blue 100 Tint',
  /** @color Primary Blue / 200 Tint */
  primaryBlue200 = 'Primary Blue 200 Tint',
  /** @color Primary Blue / 300 Tint */
  primaryBlue300 = 'Primary Blue 300 Tint',
  /** @color Primary Blue / 400 Tint */
  primaryBlue400 = 'Primary Blue 400 Tint',

  /** @color Primary Blue / 500 Base */
  primaryBlue500 = 'Primary Blue 500 Base',

  /** @color Primary Blue / 600 Shade */
  primaryBlue600 = 'Primary Blue 600 Shade',
  /** @color Primary Blue / 700 Shade */
  primaryBlue700 = 'Primary Blue 700 Shade',
  /** @color Primary Blue / 800 Shade */
  primaryBlue800 = 'Primary Blue 800 Shade',
  /** @color Primary Blue / 900 Shade */
  primaryBlue900 = 'Primary Blue 900 Shade',

  // Primary Purple
  /** @color Primary Purple / 5% */
  primaryPurpleOpacity5 = 'Primary Purple 5%',

  /** @color Primary Purple / 100 Tint */
  primaryPurple100 = 'Primary Purple 100 Tint',
  /** @color Primary Purple / 200 Tint */
  primaryPurple200 = 'Primary Purple 200 Tint',
  /** @color Primary Purple / 300 Tint */
  primaryPurple300 = 'Primary Purple 300 Tint',
  /** @color Primary Purple / 400 Tint */
  primaryPurple400 = 'Primary Purple 400 Tint',

  /** @color Primary Purple / 500 Base */
  primaryPurple500 = 'Primary Purple 500 Base',

  /** @color Primary Purple / 600 Shade */
  primaryPurple600 = 'Primary Purple 600 Shade',
  /** @color Primary Purple / 700 Shade */
  primaryPurple700 = 'Primary Purple 700 Shade',
  /** @color Primary Purple / 800 Shade */
  primaryPurple800 = 'Primary Purple 800 Shade',
  /** @color Primary Purple / 900 Shade */
  primaryPurple900 = 'Primary Purple 900 Shade',

  // Primary Red
  /** @color Primary Red / 5% */
  primaryRedOpacity5 = 'Primary Red 5%',

  /** @color Primary Red / 100 Tint */
  primaryRed100 = 'Primary Red 100 Tint',
  /** @color Primary Red / 200 Tint */
  primaryRed200 = 'Primary Red 200 Tint',
  /** @color Primary Red / 300 Tint */
  primaryRed300 = 'Primary Red 300 Tint',
  /** @color Primary Red / 400 Tint */
  primaryRed400 = 'Primary Red 400 Tint',

  /** @color Primary Red / 500 Base */
  primaryRed500 = 'Primary Red 500 Base',

  /** @color Primary Red / 600 Shade */
  primaryRed600 = 'Primary Red 600 Shade',
  /** @color Primary Red / 700 Shade */
  primaryRed700 = 'Primary Red 700 Shade',
  /** @color Primary Red / 800 Shade */
  primaryRed800 = 'Primary Red 800 Shade',
  /** @color Primary Red / 900 Shade */
  primaryRed900 = 'Primary Red 900 Shade',

  // Primary Orange
  /** @color Primary Orange / 5% */
  primaryOrangeOpacity5 = 'Primary Orange 5%',

  /** @color Primary Orange / 100 Tint */
  primaryOrange100 = 'Primary Orange 100 Tint',
  /** @color Primary Orange / 200 Tint */
  primaryOrange200 = 'Primary Orange 200 Tint',
  /** @color Primary Orange / 300 Tint */
  primaryOrange300 = 'Primary Orange 300 Tint',
  /** @color Primary Orange / 400 Tint */
  primaryOrange400 = 'Primary Orange 400 Tint',

  /** @color Primary Orange / 500 Base */
  primaryOrange500 = 'Primary Orange 500 Base',

  /** @color Primary Orange / 600 Shade */
  primaryOrange600 = 'Primary Orange 600 Shade',
  /** @color Primary Orange / 700 Shade */
  primaryOrange700 = 'Primary Orange 700 Shade',
  /** @color Primary Orange / 800 Shade */
  primaryOrange800 = 'Primary Orange 800 Shade',
  /** @color Primary Orange / 900 Shade */
  primaryOrange900 = 'Primary Orange 900 Shade',

  /**
   *
   * Greys
   *
   */
  /** @color Grey / 100 */
  grey100 = 'Grey 100',
  /** @color Grey / 200 */
  grey200 = 'Grey 200',
  /** @color Grey / 300 */
  grey300 = 'Grey 300',
  /** @color Grey / 400 */
  grey400 = 'Grey 400',
  /** @color Grey / 500 */
  grey500 = 'Grey 500',
  /** @color Grey / 600 */
  grey600 = 'Grey 600',
  /** @color Grey / 700 */
  grey700 = 'Grey 700',

  /**
   *
   * Whites
   *
   */
  /** @color White / 100% */
  white100 = 'White 100%',
  /** @color White / 90% */
  white90 = 'White 90%',
  /** @color White / 80% */
  white80 = 'White 80%',
  /** @color White / 70% */
  white70 = 'White 70%',
  /** @color White / 60% */
  white60 = 'White 60%',
  /** @color White / 50% */
  white50 = 'White 50%',
  /** @color White / 40% */
  white40 = 'White 40%',
  /** @color White / 30% */
  white30 = 'White 30%',
  /** @color White / 20% */
  white20 = 'White 20%',
  /** @color White / 10% */
  white10 = 'White 10%',
  /** @color White / 5% */
  white5 = 'White 5%',
  /** @color White / 1% */
  white1 = 'White 1%',

  /**
   *
   * Blacks
   *
   */
  /** @color Black / 100% */
  black100 = 'Black 100%',
  /** @color Black / 90% */
  black90 = 'Black 90%',
  /** @color Black / 80% */
  black80 = 'Black 80%',
  /** @color Black / 70% */
  black70 = 'Black 70%',
  /** @color Black / 60% */
  black60 = 'Black 60%',
  /** @color Black / 50% */
  black50 = 'Black 50%',
  /** @color Black / 40% */
  black40 = 'Black 40%',
  /** @color Black / 30% */
  black30 = 'Black 30%',
  /** @color Black / 20% */
  black20 = 'Black 20%',
  /** @color Black / 10% */
  black10 = 'Black 10%',
  /** @color Black / 5% */
  black5 = 'Black 5%',
  /** @color Black / 1% */
  black1 = 'Black 1%',

  /**
   *
   * Others
   *
   */
  /** @color Other / Pearl */
  otherPearl = 'Other Pearl',
  /** @color Other / Apple */
  otherApple = 'Other Apple',
  /** @color Other / Lilac */
  otherLilac = 'Other Lilac',
  /** @color Other / Mauve */
  otherMauve = 'Other Mauve',
  /** @color Other / Milk Tea */
  otherMilkTea = 'Other Milk Tea',
  /** @color Other / Honey */
  otherHoney = 'Other Honey',

  /**
   *
   * Snacks
   *
   */
  /** @color Snack / Success Pad */
  snackSuccessPad = 'Snack Success Pad',
  /** @color Snack / Success Base */
  snackSuccessBase = 'Snack Success Base',

  /** @color Snack / Info Pad */
  snackInfoPad = 'Snack Info Pad',
  /** @color Snack / Info Base */
  snackInfoBase = 'Snack Info Base',

  /** @color Snack / Error Pad */
  snackErrorPad = 'Snack Error Pad',
  /** @color Snack / Error Base */
  snackErrorBase = 'Snack Error Base',

  /** @color Snack / Warning 1 Pad */
  snackWarning1Pad = 'Snack Warning 1 Pad',
  /** @color Snack / Warning 1 Base */
  snackWarning1Base = 'Snack Warning 1 Base',

  /** @color Snack / Warning 2 Pad */
  snackWarning2Pad = 'Snack Warning 2 Pad',
  /** @color Snack / Warning 2 Base */
  snackWarning2Base = 'Snack Warning 2 Base'

  // TBD Gradients
}

export const calculateShade = (colorValue: string, shade: ColorShade) => {
  const color = hexToRGB(colorValue);

  const map = {
    100: () => rgbTint(color, 9),
    200: () => rgbTint(color, 7),
    300: () => rgbTint(color, 5),
    400: () => rgbTint(color, 3),
    500: () => color,
    600: () => rgbShade(color, 1),
    700: () => rgbShade(color, 3),
    800: () => rgbShade(color, 5),
    900: () => rgbShade(color, 7)
  };

  return `#${rgbToHex(map[shade]())}`;
};

const nectGreen = '03AFA7';
const primaryBlue = '2F80ED';
const primaryPurple = '646EE5';
const primaryRed = 'D33367';
const primaryOrange = 'F8A427';

export const NectDefaultColor = {
  // Nect Green
  [Color.nectGreenOpacity5]: hexToRGBAString(nectGreen, 0.05),
  [Color.nectGreen100]: calculateShade(nectGreen, 100),
  [Color.nectGreen200]: calculateShade(nectGreen, 200),
  [Color.nectGreen300]: calculateShade(nectGreen, 300),
  [Color.nectGreen400]: calculateShade(nectGreen, 400),
  [Color.nectGreen500]: calculateShade(nectGreen, 500),
  [Color.nectGreen600]: calculateShade(nectGreen, 600),
  [Color.nectGreen700]: calculateShade(nectGreen, 700),
  [Color.nectGreen800]: calculateShade(nectGreen, 800),
  [Color.nectGreen900]: calculateShade(nectGreen, 900),

  // Primary Blue
  [Color.primaryBlueOpacity5]: hexToRGBAString(primaryBlue, 0.05),
  [Color.primaryBlue100]: calculateShade(primaryBlue, 100),
  [Color.primaryBlue200]: calculateShade(primaryBlue, 200),
  [Color.primaryBlue300]: calculateShade(primaryBlue, 300),
  [Color.primaryBlue400]: calculateShade(primaryBlue, 400),
  [Color.primaryBlue500]: calculateShade(primaryBlue, 500),
  [Color.primaryBlue600]: calculateShade(primaryBlue, 600),
  [Color.primaryBlue700]: calculateShade(primaryBlue, 700),
  [Color.primaryBlue800]: calculateShade(primaryBlue, 800),
  [Color.primaryBlue900]: calculateShade(primaryBlue, 900),

  // Primary Purple
  [Color.primaryPurpleOpacity5]: hexToRGBAString(primaryPurple, 0.05),
  [Color.primaryPurple100]: calculateShade(primaryPurple, 100),
  [Color.primaryPurple200]: calculateShade(primaryPurple, 200),
  [Color.primaryPurple300]: calculateShade(primaryPurple, 300),
  [Color.primaryPurple400]: calculateShade(primaryPurple, 400),
  [Color.primaryPurple500]: calculateShade(primaryPurple, 500),
  [Color.primaryPurple600]: calculateShade(primaryPurple, 600),
  [Color.primaryPurple700]: calculateShade(primaryPurple, 700),
  [Color.primaryPurple800]: calculateShade(primaryPurple, 800),
  [Color.primaryPurple900]: calculateShade(primaryPurple, 900),

  // Primary Red
  [Color.primaryRedOpacity5]: hexToRGBAString(primaryRed, 0.05),
  [Color.primaryRed100]: calculateShade(primaryRed, 100),
  [Color.primaryRed200]: calculateShade(primaryRed, 200),
  [Color.primaryRed300]: calculateShade(primaryRed, 300),
  [Color.primaryRed400]: calculateShade(primaryRed, 400),
  [Color.primaryRed500]: calculateShade(primaryRed, 500),
  [Color.primaryRed600]: calculateShade(primaryRed, 600),
  [Color.primaryRed700]: calculateShade(primaryRed, 700),
  [Color.primaryRed800]: calculateShade(primaryRed, 800),
  [Color.primaryRed900]: calculateShade(primaryRed, 900),

  // Primary Orange
  [Color.primaryOrangeOpacity5]: hexToRGBAString(primaryOrange, 0.05),
  [Color.primaryOrange100]: calculateShade(primaryOrange, 100),
  [Color.primaryOrange200]: calculateShade(primaryOrange, 200),
  [Color.primaryOrange300]: calculateShade(primaryOrange, 300),
  [Color.primaryOrange400]: calculateShade(primaryOrange, 400),
  [Color.primaryOrange500]: calculateShade(primaryOrange, 500),
  [Color.primaryOrange600]: calculateShade(primaryOrange, 600),
  [Color.primaryOrange700]: calculateShade(primaryOrange, 700),
  [Color.primaryOrange800]: calculateShade(primaryOrange, 800),
  [Color.primaryOrange900]: calculateShade(primaryOrange, 900),

  // Greys
  [Color.grey100]: '#FAFBFC',
  [Color.grey200]: '#F0F1F2',
  [Color.grey300]: '#DDDEE0',
  [Color.grey400]: '#A9ABB0',
  [Color.grey500]: '#797C81',
  [Color.grey600]: '#4B4F55',
  [Color.grey700]: '#0A0D1B',

  // Whites
  [Color.white100]: '#FFFFFF',
  [Color.white90]: 'rgba(255, 255, 255, 0.9)',
  [Color.white80]: 'rgba(255, 255, 255, 0.8)',
  [Color.white70]: 'rgba(255, 255, 255, 0.7)',
  [Color.white60]: 'rgba(255, 255, 255, 0.6)',
  [Color.white50]: 'rgba(255, 255, 255, 0.5)',
  [Color.white40]: 'rgba(255, 255, 255, 0.4)',
  [Color.white30]: 'rgba(255, 255, 255, 0.3)',
  [Color.white20]: 'rgba(255, 255, 255, 0.2)',
  [Color.white10]: 'rgba(255, 255, 255, 0.1)',
  [Color.white5]: 'rgba(255, 255, 255, 0.05)',
  [Color.white1]: 'rgba(255, 255, 255, 0.01)',

  // Blacks
  [Color.black100]: '#000000',
  [Color.black90]: 'rgba(0, 0, 0, 0.9)',
  [Color.black80]: 'rgba(0, 0, 0, 0.8)',
  [Color.black70]: 'rgba(0, 0, 0, 0.7)',
  [Color.black60]: 'rgba(0, 0, 0, 0.6)',
  [Color.black50]: 'rgba(0, 0, 0, 0.5)',
  [Color.black40]: 'rgba(0, 0, 0, 0.4)',
  [Color.black30]: 'rgba(0, 0, 0, 0.3)',
  [Color.black20]: 'rgba(0, 0, 0, 0.2)',
  [Color.black10]: 'rgba(0, 0, 0, 0.1)',
  [Color.black5]: 'rgba(0, 0, 0, 0.05)',
  [Color.black1]: 'rgba(0, 0, 0, 0.01)',

  // Others
  [Color.otherPearl]: '#FBE1CE',
  [Color.otherApple]: '#C7F1DC',
  [Color.otherLilac]: '#BABFFF',
  [Color.otherMauve]: '#EDCEFF',
  [Color.otherMilkTea]: '#FFD4D4',
  [Color.otherHoney]: '#F5D424',

  // Snacks
  [Color.snackSuccessPad]: '#F0FDFB',
  [Color.snackSuccessBase]: '#11C6B0',

  [Color.snackInfoPad]: '#F2F9FF',
  [Color.snackInfoBase]: '#0A6CFF',

  [Color.snackErrorPad]: '#FFF2F3',
  [Color.snackErrorBase]: '#F01C4F',

  [Color.snackWarning1Pad]: '#FFF7F1',
  [Color.snackWarning1Base]: '#FF6F07',

  [Color.snackWarning2Pad]: '#F7F4FF',
  [Color.snackWarning2Base]: '#5820F8'
};

export const NectDefaultTheme = {
  name: 'Nect Default',

  heading: {
    // fontFamily: `${poppins.style.fontFamily}, sans-serif`,
    color: {
      default: NectDefaultColor[Color.grey700]
    }
  },
  button: {
    // // fontFamily: `${poppins.style.fontFamily}, sans-serif`,
    color: {
      default: NectDefaultColor[Color.grey700]
    }
  },
  text: {
    // // fontFamily: `${heebo.style.fontFamily}, sans-serif`,
    color: {
      default: NectDefaultColor[Color.grey700]
    }
  },
  table: {
    // // fontFamily: `${poppins.style.fontFamily}, sans-serif`,
    color: {
      default: NectDefaultColor[Color.grey700]
    }
  },

  color: {
    ...NectDefaultColor
  },

  borderRadius: {
    /**  @size None */
    None: '0',
    /**  @size 4px */
    XS: '4px',
    /**  @size 8px */
    S: '8px',
    /**  @size 16px */
    M: '16px',
    /**  @size 24px */
    L: '24px',
    /**  @size 9999px */
    Round: '9999px',
    /**  @size 50% */
    Ellipse: '50%'
  },
  spacing: {
    /**  @size 4px */
    XXXS: '4px',
    /**  @size 8px */
    XXS: '8px',
    /**  @size 12px */
    XS: '12px',
    /**  @size 16px */
    S: '16px',
    /**  @size 24px */
    M: '24px',
    /**  @size 32px */
    L: '32px',
    /**  @size 40px */
    XL: '40px',
    /**  @size 64px */
    XXL: '64px',
    /**  @size 128px */
    XXXL: '128px'
  },
  elevation: {
    shadow: {
      1: '0px 1px 3px',
      2: '0px 2px 6px',
      3: '0px 8px 16px',
      4: '0px 12px 24px'
    },
    color: {
      light: {
        1: 'rgba(0, 6, 19, 0.08)',
        2: 'rgba(0, 6, 19, 0.1)',
        3: 'rgba(0, 0, 0, 0.08)',
        4: 'rgba(0, 0, 0, 0.08)'
      },
      dark: {
        1: '#010205',
        2: 'rgba(1, 2, 5, 0.8)',
        3: 'rgba(1, 2, 5, 0.72)',
        4: 'rgba(1, 2, 5, 0.72)'
      },
      nectGreen: {
        1: 'rgba(0, 95, 90, 0.4)',
        2: 'rgba(0, 95, 90, 0.32)',
        3: 'rgba(0, 95, 90, 0.16)',
        4: 'rgba(0, 95, 90, 0.16)'
      },
      invertNectGreen: {
        1: 'rgba(131, 194, 191, 0.24)',
        2: 'rgba(131, 194, 191, 0.16)',
        3: 'rgba(131, 194, 191, 0.16)',
        4: 'rgba(131, 194, 191, 0.24)'
      }
    }
  },
  breakpoint: {
    mobile: {
      min: '0px',
      max: '767px'
    },
    tablet: {
      min: '768px',
      max: '1279px'
    },
    desktop: {
      min: '1280px'
    }
  },
  container: {
    desktop: '1184px'
  }
};

type IThemeNectDefault = typeof NectDefaultTheme;
type TextAlign = 'center' | 'left' | 'right' | 'justify';

const TextButtonBase = css`
  font-family: ${props => props.theme.button.fontFamily};
  font-style: normal;
  color: ${props =>
    (props as any).color
      ? props.theme.color[(props as any).color as Color]
      : 'inherit'};
`;

export const TextButtonMediumCSS = css`
  ${TextButtonBase}
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`;

interface HeadlineProps {
  /**
    Color
    */
  color?: Color;

  /**
    Margin
    */
  margin?: SpacingProp;

  /**
    Text content
    */
  children?: string;

  /**
    Text align
    */
  align?: TextAlign;
}

type HeadlinePropsStyled = Omit<HeadlineProps, 'children'>;

export type ITheme = IThemeNectDefault;

export type TetAlign = 'center' | 'left' | 'right' | 'justify';

export type Spacing = keyof typeof NectDefaultTheme.spacing;
export type SpacingProp =
  | Spacing
  | {
      top?: Spacing;
      left?: Spacing;
      bottom?: Spacing;
      right?: Spacing;
    };

const spacingPropToCSS = (
  prop: SpacingProp,
  type: 'margin' | 'padding',
  theme: ITheme
) => {
  if (typeof prop === 'string') {
    return `${`${type}:${theme.spacing[prop]}`};`;
  }

  let bottom = '';
  if (prop.bottom) {
    bottom = `${type}-bottom: ${theme.spacing[prop.bottom]};`;
  }
  let top = '';
  if (prop.top) {
    top = `${type}-top: ${theme.spacing[prop.top]};`;
  }
  let left = '';
  if (prop.left) {
    left = `${type}-left: ${theme.spacing[prop.left]};`;
  }
  let right = '';
  if (prop.right) {
    right = `${type}-right: ${theme.spacing[prop.right]};`;
  }

  return `${bottom}${top}${left}${right}`;
};

const HeadlineCSS = css<HeadlinePropsStyled>`
  font-family: ${props => props.theme.heading.fontFamily};
  font-style: normal;
  font-weight: bold;
  color: ${props =>
    props.color
      ? props.theme.color[props.color]
      : props.theme.heading.color.default};
  text-align: ${props => props.align ?? 'left'};
  margin: 0;
  ${({ margin, theme }) => margin && spacingPropToCSS(margin, 'margin', theme)}
`;

// H6 Bold
export const HeadlineH6BoldCSS = css<HeadlinePropsStyled>`
  ${HeadlineCSS}
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.045em;
`;

const TextBase = css`
  font-family: ${props => props.theme.text.fontFamily};
  font-style: normal;
  color: ${props =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props.theme.color[((props as any).color as Color) ?? Color.grey700]};
`;

/* Text Body Small */
export const TextBodySmallCSS = css`
  ${TextBase}
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.5px;
`;

// Button Large
const TextButtonLargeCSS = css`
  ${TextButtonBase}
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`;

export const TextButtonLarge = styled.button`
  ${TextButtonLargeCSS}
`;

interface TextProps {
  /**
    Text to be rendered
    */
  children: React.ReactNode;

  /**
    Color
    */
  color?: Color;
}

type TextParagraphProps = TextProps & {
  /**
    Text align
    */
  align?: TextAlign;
};

export const TextBodySmallParagraph = styled.p<TextParagraphProps>`
  ${TextBodySmallCSS}
  text-align: ${props => props.align ?? 'left'};
`;
